// src/routes/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WebAppRoutes from './WebAppRoutes';
import DashboardRoutes from './DashboardRoutes';
import Login from '../views/Login'; // Import the Login component

const AppRouter = () => {
    return (
        <Router>
        <Routes>
            {/* Web App Routes */}
            <Route path="/*" element={<WebAppRoutes />} />

            {/* Dashboard Routes */}
            <Route path="/dashboard/*" element={<DashboardRoutes />} />

            {/* Login Route */}
            <Route path="/login" element={<Login />} />
            </Routes>
       </Router>
    );
};

export default AppRouter;
