// src/routes/DashboardRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardHome from '../views/dashboard/DashboardHome';
import Settings from '../views/dashboard/Settings';

const DashboardRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<DashboardHome />} />
            <Route path="/settings" element={<Settings />} />
        </Routes>
    );
};

export default DashboardRoutes;