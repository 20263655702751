import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Header = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <nav
            className="navbar navbar-standard navbar-expand-lg fixed-top navbar-dark bg-white"
            data-navbar-darken-on-scroll="data-navbar-darken-on-scroll"
            style={{ borderBottom: "0.1em solid rgba(0, 0, 0, 0.2)" }}
        >
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <span className="text-dark dark__text-white">
                        <span className="text-primary-custom">await</span>
                        <span className="text-dark">ware</span>
                    </span>
                </Link>
                <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarStandard"
                    aria-controls="navbarStandard"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse scrollbar " id="navbarStandard">

                    <ul
                        className="navbar-nav"
                        data-top-nav-dropdowns="data-top-nav-dropdowns"
                    >
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle text-dark"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="Services"
                            >
                                Services
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-card border-0 mt-0"
                                aria-labelledby="Services"
                            >
                                <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                    <Link
                                        className="dropdown-item link-600 fw-medium"
                                        to="/it-development-support-services"
                                    >
                                        IT Development | support
                                    </Link>

                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle text-dark"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="Solutions"
                            >
                                Solutions
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-card border-0 mt-0"
                                aria-labelledby="Solutions"
                            >
                                <div className="card navbar-card-app shadow-none dark__bg-1000">
                                    <div className="card-body scrollbar max-h-dropdown">
                                        <img
                                            className="img-dropdown"
                                            src=""
                                            width={130}
                                            alt=""
                                        />
                                        <div className="row">
                                            <div className="col-8 col-md-5">
                                                <div className="nav flex-column">
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/calendar.html"
                                                    >
                                                        ERP
                                                    </a>
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/chat.html"
                                                    >
                                                        CRM
                                                    </a>
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/kanban.html"
                                                    >
                                                        CMS
                                                    </a>
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/email/inbox.html"
                                                    >
                                                        Point of sale
                                                    </a>
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/email/email-detail.html"
                                                    >
                                                        Project Management
                                                    </a>
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/email/compose.html"
                                                    >
                                                        Task Management
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-4 col-md-4">
                                                <div className="nav flex-column">
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/e-commerce/product/product-list.html"
                                                    >
                                                        Human Resource
                                                    </a>
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/e-commerce/product/product-grid.html"
                                                    >
                                                        Education
                                                    </a>
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/e-commerce/product/product-details.html"
                                                    >
                                                        E Commerce
                                                    </a>
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/e-commerce/orders/order-list.html"
                                                    >
                                                        Web Portal
                                                    </a>
                                                    <a
                                                        className="nav-link py-1 link-600 fw-medium"
                                                        href="../app/e-commerce/orders/order-details.html"
                                                    >
                                                        Marketing & Advertising
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle text-dark"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="About"
                            >
                                About Us
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-card border-0 mt-0"
                                aria-labelledby="About"
                            >
                                <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                    <Link
                                        className="dropdown-item link-600 fw-medium"
                                        to="/about-us"
                                    >
                                        awaitware About Explained
                                    </Link>
                                    <Link
                                        className="dropdown-item link-600 fw-medium"
                                        to="/blog"
                                    >
                                        Blog
                                    </Link>
                                    <Link
                                        className="dropdown-item link-600 fw-medium"
                                        to="/testimonials"
                                    >
                                        Testimonials
                                    </Link>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-dark" to="/pricing" >
                                <span
                                    className="d-lg-inline-block"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Dashboard"
                                >
                                </span>
                                Pricing
                            </Link>
                        </li>
                    </ul>

                    <ul className="navbar-nav ms-auto">
                        {/*<li className="nav-item dropdown">*/}
                        {/*    <a*/}
                        {/*        className="nav-link dropdown-toggle text-dark"*/}
                        {/*        id="navbarDropdownLogin"*/}
                        {/*        href="#"*/}
                        {/*        role="button"*/}
                        {/*        data-bs-toggle="dropdown"*/}
                        {/*        aria-haspopup="true"*/}
                        {/*        aria-expanded="false"*/}
                        {/*    >*/}
                        {/*        Login*/}
                        {/*    </a>*/}
                        {/*    <div*/}
                        {/*        className="dropdown-menu dropdown-menu-end dropdown-menu-card"*/}
                        {/*        aria-labelledby="navbarDropdownLogin"*/}
                        {/*    >*/}
                        {/*        <div className="card shadow-none navbar-card-login">*/}
                        {/*            <div className="card-body fs--1 p-4 fw-normal">*/}
                        {/*                <div className="row text-start justify-content-between align-items-center mb-2">*/}
                        {/*                    <div className="col-auto">*/}
                        {/*                        <h5 className="mb-0">Log in</h5>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="col-auto">*/}
                        {/*                        <p className="fs--1 text-600 mb-0">*/}
                        {/*                            or{" "}*/}
                        {/*                            <a href="../pages/authentication/simple/register.html">*/}
                        {/*                                Create an account*/}
                        {/*                            </a>*/}
                        {/*                        </p>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <form>*/}
                        {/*                    <div className="mb-3">*/}
                        {/*                        <input*/}
                        {/*                            className="form-control"*/}
                        {/*                            type="email"*/}
                        {/*                            placeholder="Email address"*/}
                        {/*                        />*/}
                        {/*                    </div>*/}
                        {/*                    <div className="mb-3">*/}
                        {/*                        <input*/}
                        {/*                            className="form-control"*/}
                        {/*                            type="password"*/}
                        {/*                            placeholder="Password"*/}
                        {/*                        />*/}
                        {/*                    </div>*/}
                        {/*                    <div className="row flex-between-center">*/}
                        {/*                        <div className="col-auto">*/}
                        {/*                            <div className="form-check mb-0">*/}
                        {/*                                <input*/}
                        {/*                                    className="form-check-input"*/}
                        {/*                                    type="checkbox"*/}
                        {/*                                    id="modal-checkbox"*/}
                        {/*                                />*/}
                        {/*                                <label*/}
                        {/*                                    className="form-check-label mb-0"*/}
                        {/*                                    htmlFor="modal-checkbox"*/}
                        {/*                                >*/}
                        {/*                                    Remember me*/}
                        {/*                                </label>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="col-auto">*/}
                        {/*                            <a*/}
                        {/*                                className="fs--1"*/}
                        {/*                                href="../pages/authentication/simple/forgot-password.html"*/}
                        {/*                            >*/}
                        {/*                                Forgot Password?*/}
                        {/*                            </a>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="mb-3">*/}
                        {/*                        <button*/}
                        {/*                            className="btn btn-primary d-block w-100 mt-3"*/}
                        {/*                            type="submit"*/}
                        {/*                            name="submit"*/}
                        {/*                        >*/}
                        {/*                            Log in*/}
                        {/*                        </button>*/}
                        {/*                    </div>*/}
                        {/*                </form>*/}
                        {/*                <div className="position-relative mt-4">*/}
                        {/*                    <hr className="bg-300" />*/}
                        {/*                    <div className="divider-content-center">*/}
                        {/*                        or log in with*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className="row g-2 mt-2">*/}
                        {/*                    <div className="col-sm-6">*/}
                        {/*                        <a*/}
                        {/*                            className="btn btn-outline-google-plus btn-sm d-block w-100"*/}
                        {/*                            href="#"*/}
                        {/*                        >*/}
                        {/*                            <span*/}
                        {/*                                className="fab fa-google-plus-g me-2"*/}
                        {/*                                data-fa-transform="grow-8"*/}
                        {/*                            />{" "}*/}
                        {/*                            google*/}
                        {/*                        </a>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="col-sm-6">*/}
                        {/*                        <a*/}
                        {/*                            className="btn btn-outline-facebook btn-sm d-block w-100"*/}
                        {/*                            href="#"*/}
                        {/*                        >*/}
                        {/*                            <span*/}
                        {/*                                className="fab fa-facebook-square me-2"*/}
                        {/*                                data-fa-transform="grow-8"*/}
                        {/*                            />{" "}*/}
                        {/*                            facebook*/}
                        {/*                        </a>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                       

                        <li className="nav-item">
                            <button className="btn btn-dark btn-sm mt-1" type="button" onClick={handleClick}>
                                Contact Us
                            </button>
                        </li>
                    </ul>

                </div>
            </div>
        </nav>
    );
}
export default Header;
