import React from 'react';
import Header from './WebAppHeader';
import Footer from './WebAppFooter';

const Layout = ({ children }) => {
    return (
        <div>
            <style>
                {`
                body {
                    background-color: white;
                }

                .fixed-bottom-footer {
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    background-color: white;
                    z-index: 1000;
                }

                .custom-paragraph {
                    font-size: 16px;
                    line-height: 1.5;
                    text-align: justify;
                }

                .bg-primary-custom {
                    background: radial-gradient(circle, white, #dedff4);
                }

                .text-primary-custom {
                    color: #5e17eb;
                }

                .border-box {
                    border: 2px solid #5271ff;
                    border-radius: 8px;
                    padding: 16px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    background-color: #ffffff;
                    max-width: 300px;
                    margin: 20px auto;
                }

                .form-control:focus,
                .form-select:focus,
                .form-label:focus,
                .dataTable-input:focus {
                    box-shadow: none !important;
                    outline: none !important;
                }

                .form-control:focus,
                .form-select:focus,
                .form-label:focus,
                .dataTable-input:focus {
                    color: #69707a;
                    background-color: #fff;
                    border-color: #410294;
                }
                `}
            </style>
            <main>
                <Header />
                <section className="py-0 overflow-hidden light mt-4">
                    {children}
                </section>
                <Footer />
            </main>
        </div>
    );
}

export default Layout;
