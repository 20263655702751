// src/controllers/webapp/ApiController.js
import config from '../../config';

export async function fetchData() {
    try {
        const response = await fetch(`${config.apiBaseUrl}Company?userId=1`);
        if (!response.ok) {
            // Improved error handling
            const errorText = await response.text(); // Read error message from response if available
            throw new Error(`Network response was not ok: ${response.statusText}. ${errorText}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch data:', error);
        throw error; // Ensure the error is propagated
    }
}
