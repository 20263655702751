// src/views/Login.js
import React from 'react';

const Login = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h2>Login Page</h2>
            {/* Add your login form or content here */}
            <form>
                <div>
                    <label>
                        Username:
                        <input type="text" name="username" />
                    </label>
                </div>
                <div>
                    <label>
                        Password:
                        <input type="password" name="password" />
                    </label>
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;