import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <section className="py-0 bg-white light fixed-bottom-footer ">
            <div>
                <hr className="my-0 text-600 opacity-25" />
                <div className="container py-3 text-dark">
                    <div className="row justify-content-between fs--1">
                        <div className="col-12 col-sm-auto text-center">
                            <p className="mb-0 text-600 opacity-85 ">
                                @ 2024, All rights reserved{" "}
                                <span className="d-none d-sm-inline-block"> </span>
                                <br className="d-sm-none" /> {" "}
                                <a
                                    className="text-dark opacity-85"
                                    href=""
                                >
                                    awaitware
                                </a>
                            </p>
                        </div>
                        <div className="col-12 col-sm-auto text-center">
                            <p className="mb-0 text-600 opacity-85">v1.0.0</p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Footer;
