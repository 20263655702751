// src/views/webapp/Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import WebAppLayout from '../../components/layout/webapp/WebAppLayout';

const Home = () => {
    const navigate = useNavigate();

    // Function to handle redirect to the dashboard
    const redirectToDashboard = () => {
        navigate('/dashboard');
    };

    // Function to handle redirect to the login page
    const redirectToLogin = () => {
        navigate('/login');
    };

    return (
        <WebAppLayout>
            <section className="bg-primary-custom box-banner-right-home6">
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-md-6 col-sm-3 mt-5">
                            <h1 className="text-primary-custom text-start mt-2">The most agile freelancing team</h1>
                            <h6 className="text-dark-soft text-start custom-paragraph">At Awaitware, we are a dynamic freelancing team dedicated to crafting exceptional software solutions.
                                Our expertise spans across all types of software projects, ensuring that we can meet your unique needs with precision and creativity.</h6>
                        </div>
                        <div className="col-md-6 col-sm-3">
                            {/* <button onClick={redirectToDashboard}>Go to Dashboard</button>  
                            <button onClick={redirectToLogin}>Login</button>  */}
                        </div>
                    </div>
                </div>
            </section>
        </WebAppLayout>
    );
};

export default Home;