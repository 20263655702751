// src/views/dashboard/DashboardHome.js
import React from 'react';
import DashboardLayout from '../../components/layout/dashboard/DashboardLayout';

const DashboardHome = () => {
    return (
        <DashboardLayout>
            <h1>Dashboard Home</h1>
        </DashboardLayout>
    );
};

export default DashboardHome;