// src/views/dashboard/Settings.js
import React from 'react';
import DashboardLayout from '../../components/layout/dashboard/DashboardLayout';

const Settings = () => {
    return (
        <DashboardLayout>
            <h1>Settings Page</h1>
        </DashboardLayout>
    );
};

export default Settings;